.comingSoonScreen {
  overflow: hidden;
  width: 100%;
  .container-fluid,
  .col-md-12 {
    padding: 2px 40px;
    .comingSoonMsg {
      height: 60vh;
      width: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../utils/Images/comingSoon.png");
      .pageTitle {
        display: flex;
        align-items: center;
        align-items: center;
        gap: 10px;
        h2 {
          color: var(--light-black);
          font-family: Montserrat;
          line-height: normal;
          font-style: normal;
          font-size: 26px;
          margin: 10px 0;
          font-weight: 600;
        }
        svg {
          cursor: pointer;
        }
      }
      .wipGif {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 15px;
        width: 100%;
        h2 {
          font-family: Montserrat;
          font-size: 40px;
          font-weight: bolder;
          margin-top: 20px;
          text-align: center;
          color: var(--primary-blue);
        }
        img {
          // box-shadow: 0px 0px 20px 0px #0000001a;
          padding: 20px;
          // background-color: #fff;
          // border-radius: 50%;
          object-fit: contain;
          width: 150px;
        }
      }
    }
  }
  .footer-div {
    z-index: 999;
  }
}
@media (max-width: 992px) {
  .errorMessage img {
    height: auto;
  }
}
