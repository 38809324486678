.supportReportPopup {
  .supportReportContent {
    padding: 20px;
    text-align: center;
    .supportHeader {
      .supportTitle {
        color: var(--darker-grey);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 50px;
      }
      .supportSubtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
      }
    }
    .supportForm {
      .formGroup {
        margin-bottom: 15px;
        text-align: left;
        color: var(--darker-grey);
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }
        .formControl {
          width: 100%;
          padding: 8px;
          border: 1px solid var(--border-grey);
          border-radius: 4px;
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
        .textEditorMsg {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin-bottom: 0;
          }
          .limitMsg {
            font-size: 12px;
            font-weight: 600;
            margin-top: 5px;
          }
        }
        .textInput {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid var(--border-grey);
          border-radius: 3px;
          margin-top: 10px;
          color: var(--primary-black);
          &::placeholder {
            color: var(--border-grey);
            font-size: 12px;
          }
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
        .ql-editor {
          p {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            color: var(--primary-black);
          }
        }
      }
      .selectedType {
        .selected-option {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          color: var(--primary-black);
        }
      }
      .selectField {
        .selected-option {
          font-size: 14px;
        }
        .options {
          li {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .submitButton {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 20px;
      }
      .attachmentSection {
        .attachedImgSec {
          overflow: hidden;
          position: relative;
          border-radius: 4px;
          border: 1px solid #dee2e6;
          padding: 0;
          background: var(--primary-white);
          width: 20%;
          input.attachedInput {
            width: 265px;
            opacity: 0;
            position: relative;
            cursor: pointer;
            padding: 3px 0px;
            z-index: 2;
            transform: rotateZ(180deg);
            overflow: hidden;
          }
          .attachedIconSec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            width: 100%;
            right: 0;
            padding: 10px;
            padding-right: 2px;
            padding-top: 8px;
            p {
              margin: 0;
              color: var(--darker-grey);
              font-size: 12px !important;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            svg {
              margin-right: 6px;
              path {
                fill: var(--primary-black);
              }
            }
          }
        }
        .attachedImageParent {
          margin-top: 10px;
          width: 108px;
          position: relative;
          // left: 25%;
          .attachCrossBtn {
            position: absolute;
            right: -5px;
            top: -4px;
            border: 0;
            background-color: rgb(255 0 0);
            padding: 0;
            height: 18px;
            color: var(--primary-white);
            width: 18px;
            font-weight: 800;
            font-size: 10px;
            border-radius: 50%;
          }
          .questionImageAttParent {
            width: 108px;
            height: 75px;
            .attachedImageQuestion {
              width: 100%;
              object-fit: contain;
              height: 75px;
              overflow: hidden;
              background: var(--light-white);
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .supportReportPopup
    .supportReportContent
    .supportForm
    .attachmentSection
    .attachedImgSec {
    width: 40%;
  }
}
