#notFoundScreen {
  overflow: hidden;
  width: 100%;
  .container-fluid,
  .col-md-12 {
    padding: 0;
    .notFoundMessage {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
@media (max-width: 992px) {
  .errorMessage img {
    height: auto;
  }
}
