.instructionPopupSection.show {
  .modal-dialog {
    .modal-content {
      .modal-header {
        button.btn-close {
          position: absolute;
          top: 30px;
          border: 2px solid var(--primary-black);
          right: 30px;
          border-radius: 50%;
          filter: invert(1);
          transition: 2.5s ease-out;
          opacity: 1;
        }
      }
    }
  }
}
.instructionPopupSection {
  .modal-dialog {
    position: unset;
    .modal-content {
      padding: 20px;
      position: unset;
      .modal-header {
        position: unset;
        border: 0;
        .modal-title {
          width: 100%;
          text-align: center;
          padding: 12px;
          border-radius: 5px;
          background: var(--light-green);
          color: var(--primary-black);
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        button.btn-close {
          top: 30px;
          opacity: 0;
          border: 2px solid var(--primary-black);
          right: 30px;
          border-radius: 50%;
          filter: invert(1);
          position: absolute;
        }
      }
      .instructionQuestionSec {
        display: flex;
        align-items: start;
        .instructionQuestionNumber {
          margin-right: 21px;
          h4 {
            color: var(--light-black);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        .instructionQuestions {
          P {
            color: var(--light-black);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .modal-footer {
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 0px;
        .modalSaveCustomCss:disabled {
          color: var(--modal-btn-disable-grey);
          background-color: var(--off-white);
        }
      }
    }
  }
}
.instructionBtn {
  background: transparent;
  border: none;
  font-weight: 600;
  color: var(--primary-black);
}
.btn-primary {
  --bs-btn-color: none;
  --bs-btn-bg: none;
  --bs-btn-border-color: none;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-border-color: none;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-hover-color: none;
  --bs-btn-active-color: none;
  --bs-btn-active-bg: none;
  --bs-btn-active-border-color: none;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: none;
  --bs-btn-disabled-bg: none;
  --bs-btn-disabled-border-color: noned;
}
.popupCrossIcon {
  display: flex;
  justify-content: end;
  cursor: pointer;
}
