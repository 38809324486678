.questionList {
  min-height: calc(100vh - 105px);
  .header-items {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }
  .attempt-text {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #122529;
    /* Dark gray text */
    margin-top: 10px;
  }
  .highlight {
    font-weight: bold;
    // color: #00cbaf; /* Teal color for attempts */
    margin-right: 5px;
  }
  video {
    width: 100%;
  }
  .instructionBtn {
    font-weight: 600;
    color: var(--primary-black);
  }
  .title {
    display: flex;
    justify-content: space-between;
    .assessmenttitle {
      font-weight: 600;
    }
  }
  .instrunctioPopup {
    padding: 0 6px;
    button {
      padding-left: 0;
    }
  }
  .changeBorderColor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-top: 3.75px;
    border: 1px solid var(--primary-green);
    border-radius: 50px;
    position: relative;
    z-index: 3;
    background: var(--primary-white);
    .changeQuestionProgress {
      width: 8px;
      height: 8px;
      background: var(--primary-green);
      border-radius: 50px;
    }
  }
  .questionProgress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-top: 3.75px;
    border: 1px solid var(--division-grey);
    border-radius: 50px;
    position: relative;
    z-index: 3;
    background: var(--primary-white);
    .subQuestionProgress {
      width: 8px;
      height: 8px;
      background: var(--division-grey);
      border-radius: 50px;
    }
  }
  // only for coding question time start
  .globalCodingTime {
    display: flex;
    gap: 10px;
    .timerCoding {
      color: #5a5a5a;
      font-weight: 600;
      min-width: 80px;
      margin-top: 3px;
      max-width: 100px;
    }
  }
  @keyframes globalCodingTimeBlink {
    50% {
      opacity: 0;
    }
  }
  .globalCodingTimeBlink {
    display: flex;
    gap: 10px;
    animation: globalTimeBlink 1s infinite alternate;
    .blink {
      color: red;
      font-weight: 600;
      min-width: 100px;
      margin-top: 2px;
    }
  }
  // only for coding question time end
  // when click on next button then change the background color
  .questionSetProgress {
    border: 1px solid var(--primary-green);
    .subQuestion {
      background: var(--primary-green);
    }
  }
  .questionSet {
    border: 1px solid var(--off-white);
    border-radius: 2px;
    padding: 20px;
    overflow: auto;
    height: 442px;
    &::-webkit-scrollbar {
      width: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--disable-dark-grey);
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--light-grey);
    }
    .questionDisplay {
      display: flex;
      margin-bottom: 10px;
      .timerLogic {
        min-width: 85px;
      }
      .question {
        display: flex;
        gap: 10px;
        flex-direction: column;
        .currentQuestionIndex {
          margin: 0;
          p {
            margin-bottom: 5px;
          }
        }
        p {
          font-weight: 600;
          color: var(--darker-grey);
          margin-top: 0 !important;
          margin-bottom: 12px;
        }
        .questionlist {
          .questionDisplay {
            display: flex;
            gap: 10px;
            p {
              font-weight: 600;
              color: var(--darker-grey);
              margin-top: 0 !important;
            }
          }
          .ImageAttParent {
            margin-left: 28px;
          }
        }
      }
    }
    .option {
      color: var(--darker-grey);
      font-weight: 500;
    }
  }
  .iteration {
    display: flex;
    justify-content: space-between;
    .reportIt {
      color: var(--orange);
      font-weight: 500;
      margin-left: 5px;
      cursor: pointer;
    }
    .skipButton {
      border-radius: 5px;
      background: var(--off-white) !important;
      border: none;
      color: var(--darker-grey);
      font-weight: 500;
    }
    .disableLimitBtn {
      background: var(--light-grey) !important;
      color: var(--primary-white) !important;
    }
    .nextBtn {
      width: 159px;
      height: 40px;
      background-color: var(--primary-green);
      border: none;
      border-radius: 5px;
      color: #ffff;
    }
  }
  .horizontalLine {
    border: 1px solid var(--off-white);
  }
}
.questionListInstruction {
  font-weight: 600;
  color: var(--primary-black);
}
.parent {
  display: flex;
  justify-content: space-between;
  position: relative;
  .horizontalLines {
    position: absolute;
    background: var(--off-white);
    width: 100%;
    height: 1px;
    top: 12px;
    z-index: 2;
  }
  .horizontalLinedisable {
    position: absolute;
    background: var(--primary-green);
    z-index: 3;
    height: 1px;
    top: 12px;
  }
}
.compatibilityModal {
  .imageCapture {
    width: 30px;
    height: 30px;
    video.captureImage {
      height: 100%;
    }
  }
}
.codingQuestion {
  .codingQuestionDisplay {
    display: flex;
    gap: 10px;
    p {
      font-weight: 600;
      color: var(--darker-grey);
    }
  }
}
// Blinking CSS
.countDown {
  border: 3px solid var(--red);
  padding: 15px 0;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
// aborted timer css
.abortedTimer {
  display: flex;
  justify-content: center;
  font-weight: 700;
  color: red;
}
@keyframes blinking {
  50% {
    border-color: var(--primary-white);
  }
}
.tabchangepopup {
  display: flex;
  justify-content: center;
  padding: 32px;
  span {
    font-weight: 700;
    font-size: larger;
  }
  .reportedPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: var(--darker-grey);
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }
  }
}
// attempt count
.attempCount {
  display: flex;
  justify-content: end;
  span {
    font-weight: 600;
  }
}
.notforcodingquestion {
  position: relative;
  .recordingAction {
    background: #00000080;
    height: 50px;
    filter: brightness(0.7);
    width: calc(96% - 2px);
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 25px;
    position: absolute;
    .globalTime {
      display: flex;
      gap: 10px;
      margin-top: 16px;
      min-width: 160px;
      .timer {
        color: var(--primary-green);
        font-weight: 600;
        font-size: 24px;
      }
    }
    @keyframes globalTimeBlink {
      50% {
        opacity: 0;
      }
    }
    .globalTimeBlink {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 20px;
      min-width: 160px;
      animation: globalTimeBlink 1s infinite alternate;
      .blink {
        color: rgb(243, 45, 45);
        font-weight: 600;
        min-width: 100px;
        margin-top: 16px;
        font-size: 24px;
      }
    }
    .blinkAnime {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10px;
      height: 10px;
      background-color: #ce2b37;
      border-radius: 50%;
      position: relative;
      margin-right: 30px;
      z-index: 2;
      &:before {
        content: "";
        width: inherit;
        height: inherit;
        border: 10px solid #ce2b37;
        border-radius: inherit;
        position: absolute;
        z-index: -10;
        opacity: 0;
        animation: 2s expand cubic-bezier(0.29, 0, 0, 1) infinite;
        animation-delay: 1s;
      }
    }
  }
}
@keyframes expand {
  /* Starting at 1 to prevent IOS flicker */
  1% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 750%;
    height: 750%;
    opacity: 0;
    border-color: #fff;
    background-color: none;
    border-width: 2px;
  }
}
@media (max-width: 860px) {
  .questionList .iteration {
    flex-direction: column;
  }
}
@media (max-width: 630px) {
  header .logo-link {
    gap: 0px;
  }
  .btn {
    align-items: flex-start;
  }
  .questionList .header-items {
    gap: 3px;
    flex-wrap: wrap;
  }
  .questionList .attempt-text {
    margin-top: -25px;
  }
}
