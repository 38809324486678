.footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid var(--light-grey);
  .logo {
    position: relative;
    display: flex;
    width: 190px;
    .evluateName {
      color: var(--primary-black);
      font-weight: 500;
      margin-left: 2px;
      font-size: 25px;
    }
    .tagline {
      color: var(--primary-black);
      font-weight: 400;
      font-size: 12px;
      margin-top: -5px;
      margin-left: 2px;
      position: absolute;
    }
  }
  .middle {
    p {
      color: var(--primary-black);
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .base {
    display: flex;
    gap: 10px;
    button {
      padding: 12px 25px;
      color: var(--light-black);
      border: 0;
      font-size: 12px;
      border-radius: 30px;
      font-weight: 500;
    }
    .getSupport {
      background: var(--dark-green);
    }
    .reportBug {
      background: var(--bug-light-red);
    }
  }
}
@media (max-width: 950px) {
  .footer {
    flex-direction: column;
    gap: 20px;
  }
}
