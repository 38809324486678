.fullPageLoader {
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  height: 46vh;
  background: #e5ebe940;
  // opacity:0.5
  // background: rgba(255, 255, 255, 0.15);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // backdrop-filter: blur(1.5px);
}
