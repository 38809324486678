.right-part {
  width: 100%;
  .userContent {
    // width: 450px;
    // padding: 30px;
    .userHeadingApp {
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 700;
      line-height: 36.57px;
      text-align: center;
      color: #5a5a5a;
    }
    .userHeading {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 500;
      line-height: 24.38px;
      text-align: center;
      color: #5a5a5a;
      margin-bottom: 70px;
    }
    .errorMessage {
      margin-top: -7px;
      margin-bottom: 20px;
    }
    input {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #b3b3b3;
      border-radius: 3px;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      &::placeholder {
        color: var(--light-grey);
      }
    }
    .forgotPasswordText {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      text-align: left;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    .submitBtn {
      width: 100%; // Update for mobile responsiveness
      max-width: 450px; // Set a max-width for larger screens
      height: 40px;
      border-radius: 3px;
      margin-top: 30px;
      background-color: #2ebec6;
    }
  }
  .alreadyHaveAccout {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-black);
    .signIn-text {
      color: var(--primary-green);
      font-weight: 600;
      cursor: pointer;
    }
  }
  .password-instructions {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-top: 10px;
    margin-left: 12px;
    div {
      list-style: none;
      padding: 0px 20px;
    }
  }
}
.applicantWrapper {
  background-image: url("../../utils/Images/FullLoginBg.png");
  .container-fluid {
    padding: 0;
  }
  .loginBanner {
    background-color: #f1f1f1;
    overflow: hidden;
    height: calc(100vh - 0px);
    // background-image: url('./Group\ 82.png');
    padding: 0 !important;
  }
  .loginBanner .g-1,
  .loginBanner .gy-1 {
    --bs-gutter-y: 0rem;
  }
  .loginBanner .row {
    height: 100%;
  }
  .loginBanner .container-fluid {
    padding: 0;
    height: 100%;
  }
  .loginBanner .container-fluid .row {
    height: 100%;
  }
  .loginBanner .container-fluid .row .col-md-4 {
    padding: 0;
  }
  .loginBanner .card {
    height: 100%;
    border-radius: 0;
    border: 1px solid #ffffff;
  }
  .loginBanner .card img,
  .loginBanner .card video {
    object-fit: cover;
    height: 100%;
    aspect-ratio: 2 / 1;
    width: 100%;
    position: relative;
  }
  .mvChange {
    padding: 0 !important;
  }
  .loginForm .loginFormContent {
    background: rgb(255 255 255 / 85%);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 40px 20px;
    width: 50%;
    margin: auto;
  }
  .loginBanner .col-md-4 {
    margin-top: 0;
    padding: 0 !important;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100%;
    position: relative;
    .bottomLinks {
      display: flex;
      justify-content: end;
      gap: 10px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      span {
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 500;
        line-height: 17.07px;
        text-align: left;
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
      div {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 767px) {
  .right-part .userContent .userHeading {
    margin-bottom: 40px;
  }
  .applicantWrapper {
    .loginBanner {
      overflow: hidden;
    }
    .loginBanner .g-2,
    .loginBanner .gy-2 {
      --bs-gutter-y: 0rem;
    }
    .loginBanner .container-fluid .row .col-md-4 {
      margin: 0;
      padding: 0.5px;
    }
    .loginBanner .hideOnMv {
      display: none;
    }
    .mvChange .loginBanner {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0;
      left: 0px;
      overflow: hidden;
    }
    .loginBanner .card {
      min-height: 100px;
    }
    .loginForm {
      position: relative;
      min-height: 100vh;
    }
    .loginForm .loginFormContent {
      padding: 40px 25px;
      width: 65%;
      margin: auto;
    }
    .right-part .userContent {
      .forgotPasswordText {
        font-size: 14px;
      }
      input {
        height: auto;
        font-size: 16px;
        padding: 13px 10px;
      }
      .submitBtn {
        height: 45px;
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 580px) {
  .applicantWrapper {
    .loginForm .loginFormContent {
      width: 85%;
    }
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .applicantWrapper {
    .loginBanner {
      height: calc(100vh - 5vh);
    }
    .loginBanner .card {
      min-height: 135px;
    }
    .mvChange .loginBanner {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0;
      left: 0px;
      overflow: hidden;
    }
    .loginForm {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 100vh;
    }
    .right-part {
      .loginFormContent {
        width: 70%;
        margin: auto;
      }
      .userContent {
        padding: 20px;
        .userHeadingApp {
          font-size: 40px;
        }
        .userHeading {
          font-size: 26px;
        }
        input {
          height: auto;
          font-size: 18px;
          padding: 13px 10px;
        }
        .forgotPasswordText {
          font-size: 16px;
        }
        .submitBtn {
          height: 45px;
          font-size: 18px;
          max-width: -webkit-fill-available;
          max-width: -moz-available;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .applicantWrapper {
    .loginForm {
      .loginFormContent {
        width: 50%;
        margin: auto;
      }
    }
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .applicantWrapper {
    .loginForm {
      .loginFormContent {
        min-width: 565px;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .applicantWrapper {
    .mvChange .loginBanner {
      position: absolute;
      left: 0;
    }
    .loginForm {
      min-height: 100vh;
    }
  }
}
@media (min-width: 1022px) and (max-width: 1026px) {
  .applicantWrapper {
    .loginBanner {
      position: absolute;
      img.logoDelay {
        object-fit: contain;
      }
    }
    .forTb {
      display: none;
    }
    .loginForm {
      min-height: 100vh;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .applicantWrapper {
    .loginForm {
      .loginFormContent {
        width: 70%;
        margin: auto;
      }
      img.logoDelay {
        object-fit: contain;
      }
    }
    .mvChange {
      width: 60%;
    }
    .loginCol {
      width: 40%;
    }
  }
}
