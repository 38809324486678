.parent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: start;
  .editor {
    width: 100%;
    max-width: 845px;
  }
  .transScriptSec {
    width: calc(100% - 30%);
  }
  .resultSec {
    border: 1px solid #e0e0e0;
    padding: 12px 16px;
    margin-top: 0 !important;
  }
  .resultContent {
    background-color: #f8f8f8;
    padding: 5px 10px;
    overflow: auto;
    height: 100px;
    .resultContent::-webkit-scrollbar {
      width: 3px;
    }
    /* Track */
    .resultContent::-webkit-scrollbar-track {
      background: var(--very-light-grey);
    }
    /* Handle */
    .resultContent::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
  .testcases {
    border: 1px solid #e0e0e0;
    padding: 12px 16px;
    height: 250px;
    overflow: auto;
    .testcase {
      display: flex;
      border: 1px solid var(--red);
      margin-bottom: 12px;
      justify-content: space-between;
      padding: 5px 10px;
      align-items: center;
      .testCaseTitle {
        span {
          font-weight: 600;
          font-size: 12px;
          color: var(--darker-grey);
        }
        p {
          font-weight: 500;
          font-size: 11px;
          color: var(--darker-grey);
        }
      }
      .icon {
        width: 50px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.runBtn {
  display: flex;
  justify-content: end;
  padding: 10px;
  background-color: #f8f8f8;
}
html,
body {
  height: 100%;
  position: relative;
}
.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}
.box {
  z-index: 10;
  position: absolute;
  width: 250px;
  height: 0;
}
