html,
body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.hideContent {
  display: none;
}
.addDisableCss {
  pointer-events: none;
}
.errorMessage {
  font-size: 12px;
  color: #ff1919;
  font-weight: 600;
  margin-top: 5px;
}
body.modal-open {
  height: 100vh;
}
