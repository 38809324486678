/* OrdinalShift.css */
.number-container {
  display: inline-block;
  position: relative;
  font-size: 20px; /* Adjust font size as needed */
  margin: 10px;
}

.number {
  font-size: inherit;
}

.ordinal {
  position: absolute;
  top: 7px; /* Shift upwards */
  /* Shift rightwards */
  font-size: 14px; /* Make the ordinal smaller */
  vertical-align: super; /* Align it like a superscript */
}
