div#unauthorizedScreen {
  background-image: url("../../utils//Images/errorBG.png");
  .unauthorizeContent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 56px;
    h1 {
      text-transform: uppercase;
      font-size: 80px;
      font-weight: 700;
      line-height: 97.52px;
      margin-bottom: 20px;
      color: var(--primary-green);
      word-break: auto-phrase;
      max-width: 500px;
    }
    h3 {
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 600;
      line-height: 42.67px;
      color: var(--light-black);
    }
  }
}
@media (max-width: 768px) {
  div#unauthorizedScreen .unauthorizeContent {
    flex-direction: column;
    gap: 40px;
    h1 {
      font-size: 40px;
      text-align: center;
      line-height: 60px;
    }
    h3 {
      font-size: 26px;
      text-align: center;
      line-height: 30px;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  div#unauthorizedScreen .unauthorizeContent {
    flex-direction: column;
    gap: 40px;
    h1 {
      font-size: 40px;
      text-align: center;
      line-height: 60px;
    }
    h3 {
      font-size: 26px;
      text-align: center;
      line-height: 30px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  div#unauthorizedScreen .unauthorizeContent {
    gap: 40px;
    h1 {
      font-size: 50px;
      line-height: 60px;
    }
    h3 {
      font-size: 36px;
      line-height: 30px;
    }
  }
}
