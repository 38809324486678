.jobPage {
  .jobLogo {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 175px;
    margin: 10px 30px;
  }
  .jobHorizontalLine {
    border: 1px solid var(--primary-green);
  }
  .headerDetailSec {
    display: flex;
    background: linear-gradient(
      to bottom,
      var(--primary-white) 50%,
      #eefffb 50%
    );
    padding: 50px 100px;
    align-items: center;
    gap: 25px;
    border-bottom: 1px solid #d7d7d7;
    .companyLogo {
      width: 100px !important;
      height: 100px !important;
      img {
        width: 90px !important;
        height: 90px !important;
        object-fit: contain !important;
      }
    }
    .userImageBorder {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 20px 0px #0000001a;
      border-radius: 50%;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary-white);
      svg,
      img {
        width: 80px;
        border: 1.5px solid var(--dark-green);
        border-radius: 50%;
        height: 80px;
        object-fit: cover;
      }
      svg {
        padding: 10px;
        object-fit: cover;
      }
    }
    .jobHeader {
      color: #3d3d3d;
      font-family: Montserrat;
      h1 {
        font-size: 34px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: var(--darker-grey);
      }
    }
  }
  .jobDetailsSec {
    display: flex;
    gap: 20px;
    padding: 50px 100px;
    .jobLeftContainer {
      flex: 3;
      .jobDetailsContain {
        font-family: Montserrat;
        .jobNameApply {
          display: flex;
          justify-content: space-between;
          text-transform: capitalize;
          .jobClosedText {
            color: #d9534f;
            font-size: 14px;
            font-weight: 600;
          }
        }
        h2 {
          font-size: 34px;
          font-weight: 700;
        }
        .borderSec {
          border: 1px solid #d7d7d7;
          padding: 30px;
          border-radius: 5px;
          .jdDetailsSec {
            margin-bottom: 20px;
            .jobDetails {
              display: flex;
              flex-direction: column;
              color: var(--darker-grey);
              gap: 5px;
              .jdTitle {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.5px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
              .jdValue {
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 600;
                line-height: 24.38px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
            }
          }
          .smallSeperation {
            border: 1px solid #d7d7d7;
            width: 20%;
          }
          .titleSkill {
            margin-top: 20px;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            line-height: 21.94px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--darker-grey);
          }
          .bulletSec {
            margin-bottom: 20px;
          }
          .rolesSkillsSec {
            margin-top: 20px;
            color: var(--light-black);
            font-family: Montserrat;
            .roleSkillsHead {
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
              text-align: justified;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
            .roleSkillsDescription {
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
            }
            .readMoreBtn {
              font-size: 18px;
              font-weight: 600;
              line-height: 20px;
              text-align: right;
              color: var(--primary-green);
              cursor: pointer;
            }
          }
          .applyBtn {
            margin-top: 20px;
            padding: 10px 20px;
            background: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
              background: #0056b3;
            }
          }
        }
      }
      .linkedinSec {
        display: flex;
        justify-content: center;
        .applyLinkedin {
          margin-top: 80px;
          cursor: pointer;
          .cvUploadBtn {
            button {
              display: flex;
              gap: 10px;
            }
            .loader {
              width: 20px;
              height: 20px;
            }
          }
          .supportedTypes {
            margin-top: 10px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.07px;
            color: var(--light-grey);
          }
          .uploadedCv {
            margin-top: 25px;
            display: inline-flex;
            align-items: center;
            gap: 5px;
            border: 1px solid var(--light-grey);
            background-color: var(--off-white);
            padding: 5px;
            border-radius: 2px;
            .cvName {
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 600;
              line-height: 17.07px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: var(--light-black);
            }
            svg {
              width: 17px;
              height: 17px;
            }
          }
        }
        .verticalLine {
          border: 1px solid #b3b3b3;
          margin-left: 42px;
          margin-right: 42px;
          margin-top: 50px;
          height: 120px;
        }
        .applyWithLinkedinBtnSec {
          margin-top: 88px;
          min-width: 295px;
          span {
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #0a66c2;
            padding: 10px;
            background-color: #0a66c2;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.5px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--primary-white);
          }
        }
      }
      .whyApplyJobSection {
        border: 1px solid #d7d7d7;
        margin-top: 80px;
        padding: 30px;
        border-radius: 5px;
        font-family: Montserrat;
        .whyApply {
          font-size: 18px;
          font-weight: 500;
          line-height: 21.94px;
          color: var(--light-black);
        }
        .evluateFeatureElaborate {
          margin-top: 15px;
          .featureDetail {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: var(--light-black);
            font-family: Montserrat;
            .featureTitle {
              font-size: 16px;
              font-weight: 600;
              line-height: 19.5px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
            .featureDescription {
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
        .numericData {
          background-color: #f2fffc;
          padding: 30px;
          margin-top: 40px;
          color: var(--light-black);
          .numericValue {
            font-family: Montserrat;
            font-size: 30px;
            font-weight: 600;
            line-height: 36.57px;
            text-align: center;
          }
          .numericTitle {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.5px;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
      .jobClosedMessage {
        background-color: #ffe6e6;
        /* Light red background */
        color: #d9534f;
        /* Red text */
        padding: 15px;
        border: 1px solid #f5c6cb;
        /* Red border */
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        margin: 20px 0;
        text-align: center;
      }
    }
    .similarJobs {
      flex: 1;
      background: var(--primary-white);
      padding: 20px;
      border-radius: 5px;
      // height: fit-content;
      max-height: 1400px;
      border: 1px solid #d7d7d7;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: var(--very-light-grey);
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d5d5d5;
      }
      h3 {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: var(--light-black);
        margin-bottom: 20px;
      }
      .jobCard {
        margin-bottom: 20px;
        padding: 20px;
        background: white;
        border-radius: 5px;
        box-shadow: 0 1px 3px #0000001a;
        border: 1px solid #d7d7d7;
        .jobCardTitle {
          font-family: Montserrat;
          font-size: 20px;
          font-weight: 600;
          line-height: 24.38px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .jobCardSubDetails {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.07px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: var(--light-black);
          margin-bottom: 15px;
        }
        hr {
          border: 1px solid #d7d7d7;
        }
        .yearPlace {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 20px;
          .iconText {
            display: flex;
            gap: 15px;
            .icon {
              width: 20px;
            }
          }
        }
        .applyBtnSec {
          margin-top: 25px;
          display: flex;
          justify-content: center;
        }
      }
      .moreJobsSec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .moreJobs {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          color: var(--primary-green);
          cursor: pointer;
        }
      }
      .noSimilarJobs {
        color: var(--light-grey);
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.jobLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
@media (max-width: 960px) {
  .jobPage .jobDetailsSec .jobLeftContainer .linkedinSec .verticalLine {
    display: none;
  }
  .jobPage .jobDetailsSec .jobLeftContainer .linkedinSec {
    flex-direction: column;
  }
  .jobPage
    .jobDetailsSec
    .jobLeftContainer
    .linkedinSec
    .applyWithLinkedinBtnSec {
    min-width: 295px;
  }
}
@media (max-width: 1080px) {
  .jobPage .jobDetailsSec {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .jobPage .headerDetailSec .jobHeader h1 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .jobPage
    .jobDetailsSec
    .jobLeftContainer
    .jobDetailsContain
    .borderSec
    .jdDetailsSec {
    .mt-4 {
      margin-top: 0px !important;
    }
    .col-md-4 {
      margin-top: 15px !important;
    }
  }
  .jobPage
    .jobDetailsSec
    .jobLeftContainer
    .whyApplyJobSection
    .evluateFeatureElaborate {
    gap: 15px;
  }
}
@media (max-width: 600px) {
  .jobPage .jobDetailsSec {
    padding: 50px 20px;
  }
  .jobPage .headerDetailSec {
    padding: 50px 20px;
  }
}
@media (max-width: 400px) {
  .jobPage .headerDetailSec {
    align-items: flex-start;
    flex-direction: column;
  }
}
