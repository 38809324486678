.headerDesign {
  border: 1px solid #abffeb;
}
.headerDesignText {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  color: var(--primary-blue);
  left: 50px;
  font-weight: 600;
  padding: 0px 14px;
  background: white;
  margin-bottom: 20px;
}
.instructionModalContent {
  // min-height: 550px;
  // max-height: 650px;
  overflow: auto;
  position: relative;
  p {
    // color: black ;
    // font-weight: 600;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--very-light-grey);
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  .terms_Condition_check {
    display: flex;
    align-self: flex-start;
    align-items: center;
    html[dir="rtl"] & {
      gap: 15px;
    }
    .form-check-input {
      cursor: pointer;
    }
    span {
      font-family: Montserrat;
      font-size: 12px;
      // font-weight: 600;
    }
    .terms_Condition_text {
      color: var(--primary-blue);
      color: var(--primary-black) !important;
      font-weight: 600;
    }
  }
}
.assessmentDetailsInstruction {
  p {
    color: #000000;
  }
  li {
    color: #000000;
  }
  strong {
    color: black;
    font-weight: 600;
  }
}
.instructionPopupSection .modal-dialog .modal-content .modal-footer {
  .closeButton {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .assessmentCommonBtn button {
    &:hover {
      color: var(--primary-white);
    }
  }
}
// @media (min-width: 1200px) {
//   .modal-xl {
//     --bs-modal-width: 90%;
//   }
// }
