.walkthrough-container {
  position: relative;
  z-index: 9999;
}
.walkthrough-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0.1, 0.1, 0.1);
  z-index: 9997;
}
.highlighted-elements {
  position: fixed;
  z-index: 9999;
  box-shadow: 0 0 0 9999px rgba(0, 0.7, 0.7, 0.7); // Creates a spotlight effect
  transition: all 0.3s ease;
  border-radius: 10px;
  border: 2px solid #2aabb2;
}
.assessmentDetail-element {
  position: fixed !important;
}
.walkthrough-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // background: white;
  padding: 20px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.walkthrough-pagination {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
}
.pagination-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ddd;
}
.pagination-circle.active {
  background: #939393;
}
.walkthrough-buttons {
  display: flex;
  gap: 70px;
}
.foot {
  flex-direction: "row";
  align-items: center;
  justify-content: space-around;
  display: flex;
  gap: 30px;
  margin-top: 10px;
  button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 18px;
  }
  button:hover {
    background-color: var(--primary-green);
  }
}
@media (max-width: 767px) {
  .walkthrough-footer {
    margin-bottom: 8px;
    padding: 0;
    position: fixed;
    bottom: 0;
    h6 {
      font-size: 12px !important;
    }
  }
  .walkthrough-buttons {
    gap: 40px;
  }
  .foot {
    margin-top: 5px;
    button {
      padding: 8px 8px !important;
    }
  }
}
@media (min-width: 767px) and (max-width: 992px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1200px) and (max-width: 1400px) {
}
