p {
  font-weight: 400;
  color: var(--darker-grey);
  margin-top: -1.5px;
}
.ImageAttParent {
  width: 108px;
  height: 75px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--primary-black);
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-black);
  }
  .attachedImageQuestion {
    width: 100%;
    object-fit: contain;
    height: 75px;
    overflow: auto;
  }
}
.common {
  cursor: pointer;
}
.commonType {
  display: flex;
  align-items: center;
  cursor: pointer;
  .ImageAttParent {
    margin-left: 8px;
    margin-top: 10px;
    margin-bottom: 15px !important;
  }
}
.essayType {
  .EssayLimitAccessCssOver {
    justify-content: space-between !important;
    .charLimitMsg {
      display: block !important;
    }
  }
  .limitAccess {
    display: flex;
    justify-content: end;
    height: 40px;
    align-items: center;
    .charLimitMsg {
      font-size: 12px;
      color: var(--error-red);
      font-weight: 600;
      margin-top: 5px;
      display: none;
    }
    .charLimit {
      text-align: end;
      font-size: 12px;
      font-weight: 600;
      margin-top: 5px;
    }
    .charLimitMsg {
      font-size: 12px;
      color: var(--error-red);
      font-weight: 600;
      margin-top: 5px;
    }
    .charLimit {
      text-align: end;
      font-size: 12px;
      font-weight: 600;
      margin-top: 5px;
    }
  }
}
.videoType {
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}
