.userActive {
  height: 100vh;
  overflow: hidden;
  .userSec {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 212, 174, 0.8);
    position: absolute;
    top: 0;
    overflow: hidden;
    right: 0;
    .userContent {
      width: 35%;
      border-radius: 10px;
      background: #d1fff4;
      padding: 50px 76px;
      .otpError {
        display: flex;
        justify-content: space-between;
        .resendOtpSec {
          margin-top: -15px;
          margin-bottom: 10px;
          min-width: 50px;
        }
      }
      .errorMessage {
        margin-top: -15px;
        margin-bottom: 20px;
      }
      .userHeading {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.26px;
        text-align: center;
        color: var(--darker-grey);
      }
      .thankyouHead {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .thankyouContent {
        font-size: 18px;
        display: block;
        text-align: center;
      }
      .passwordSec {
        position: relative;
        button {
          position: absolute;
          right: 20px;
          top: 12px;
          border: 0;
          font-size: 11px;
          background-color: var(--primary-green);
          padding: 8px 12px;
          color: var(--primary-white);
          font-weight: 600;
          border-radius: 4px;
        }
        .tooltipOverCss {
          position: absolute;
          display: inline-block;
          right: 20px;
          top: 12px;
          cursor: pointer;
        }
        .loader {
          width: 14px;
          height: 14px;
          border-top: 3px solid var(--primary-white);
        }
      }
      .emailInput {
        &:disabled {
          background-color: var(--very-light-grey);
          cursor: no-drop;
          color: var(--disable-grey-shade);
        }
      }
      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--off-white);
        background: var(--primary-white);
        padding: 16px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
        &::placeholder {
          color: var(--light-grey);
        }
      }
      .firstNameInput {
        margin-top: 45px;
        text-transform: capitalize;
      }
      .lastNameInput {
        text-transform: capitalize;
      }
      .customMargin {
        margin-bottom: 30px;
      }
      .submitBtn {
        margin-top: 0px;
      }
      .submitBtn,
      .getOtpBtn {
        color: var(--primary-white);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 4px;
        background: var(--primary-green);
        width: 100%;
        padding: 15px;
        border: 0;
      }
    }
  }
}
.userSec {
  display: none;
}
@media (max-width: 768px) {
  .userActive {
    .userSec {
      .userContent {
        width: 100%;
        padding: 50px;
        margin: 15px;
      }
    }
  }
  .imageCapture {
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .userActive {
    .userSec {
      .userContent {
        width: 45%;
        padding: 50px;
        margin: 15px;
      }
    }
  }
}
