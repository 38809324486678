/* Define default theme (light) variables */
:root {
  --primary-white: #ffffff;
  --primary-black: #000000;
  --primary-green: #35d4ae;
  --secondary-green: #dffff7;
  --leaf-grean: #00bfa6;
  --dark-green: #35d4ae33;
  --light-green: #abffeb;
  --moderate-green: #d6fff1;
  --very-light-green: #f6ffff;
  --extreme-light-green: #edfffb;
  --light-grey: #b3b3b3;
  --light-black: #4d4d4d;
  --darker-grey: #5a5a5a;
  --very-light-grey: #f1f1f1;
  --dark-white: #f9f9f9;
  --off-white: #efefef;
  --light-pink: #ffb0b0;
  --disable-dark-grey: #fff3;
  --disable-light-grey: #d9d9d9;
  --disable-strip-grey: #c6c6c6;
  --loader-grey: #8f8f8f;
  --division-grey: #c7c7c7;
  --bg-white: #fafafa;
  --thumb-white: #e3e3e3;
  --border-shadow: #c0c0c033;
  --url-input-grey: #e9e9e9;
  --icons-grey: #9e9e9e;
  --fail-pink: #ffc9c9;
  --modal-btn-disable-grey: #a1a1a1;
  --bug-light-red: #ff995533;
  --red: red;
  --secondary-red: #e41b1b;
  --orange: #fe8208;
  --primary-blue: #2ebec6;
  --disable-grey-shade: #959595;
  --whitish-grey: #f3f5f5;
  --border-grey: #ccc;
  --warning-yellow: rgb(254, 239, 195);
}
/* Dark theme variables */
[data-theme="dark"] {
  --primary-white: #121212;
  --primary-black: #ffffff;
  --secondary-green: #333333;
  --primary-green: #555555;
  --light-grey: #666666;
  --light-black: #ffffff;
  --darker-grey: #ffffff;
  --very-light-green: #666666;
  --dark-white: #b3b3b3;
  --extreme-light-green: #555555;
  --whitish-grey: #333333;
}
/* Apply styles using the variables */
body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-white) !important;
  color: var(--primary-black) !important;
  &::-webkit-scrollbar {
    width: 12px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border: 3px solid #f1f1f1;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
body .container-fluid {
  padding: 0 40px;
}
.cursorPointer {
  cursor: pointer;
}
.displayNone {
  display: none;
}
.wordBreak {
  word-break: break-word;
}
.lightBtn {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  color: var(--primary-black) !important;
  background-color: var(--secondary-green) !important;
  border: 1px solid var(--primary-green) !important;
  border-radius: 5px !important;
  &:disabled {
    border: none !important;
    background-color: var(--light-grey) !important;
    border: 1px solid var(--light-grey) !important;
    color: var(--primary-white) !important;
  }
}
.whiteBtn {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 11px 28px !important;
  background-color: var(--primary-white) !important;
  border: 1px solid var(--primary-green) !important;
  color: var(--primary-green) !important;
  &:hover {
    color: var(--primary-black) !important;
  }
}
.no-scroll {
  overflow: hidden;
}
@media (max-width: 768px) {
  body .container-fluid {
    padding: 0 20px;
  }
}
body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}
body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
