.audiopermissionModal .modal-dialog .modal-content {
  width: 50%;
  margin: auto;
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .audioPermissionText {
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: var(--darker-grey);
  }
  .popupBtns {
    display: flex;
    gap: 20px;
  }
}
