.loadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--url-input-grey);
  .loader {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: inline-block;
    border-top: 5px solid #333333;
    border-right: 3px solid transparent;
  }
}
