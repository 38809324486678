.Reports {
  margin-top: 25px;
  margin-bottom: 25px;
  .reportInputField {
    textarea {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      color: var(--darker-grey);
      &::placeholder {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        color: var(--light-grey);
      }
      &:focus {
        box-shadow: none;
        outline: 0;
        border-color: var(--division-grey);
      }
    }
    .AddReportBtn {
      display: flex;
      background-color: #161a19;
    }
  }
}
