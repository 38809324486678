.confirmPopup {
  .deleteData {
    padding: 0 30px;
    .noListFound {
      height: auto;
      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: var(--darker-grey);
        margin-top: 27px;
        margin-bottom: 40px;
      }
      .deleteBtnSec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        button {
          padding: 4px 30px !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          line-height: 29px !important;
          color: var(--light-black) !important;
          border-radius: 3px !important;
        }
      }
    }
  }
  .popupCrossIcon {
    svg {
      position: absolute;
      top: 0;
      right: 1px;
    }
  }
}
@media (min-width: 992px) {
  .confirmPopup {
    .modal-lg,
    .modal-xl {
      --bs-modal-width: 530px;
    }
  }
}
.popupLabel {
  .number-container {
    margin: 0px;
    margin-right: 25px !important;
    .number {
      font-size: 16px !important;
    }
  }
}
