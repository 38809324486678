.userActive {
  height: 100vh;
  overflow: hidden;
  .userSec {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 212, 174, 0.8);
    position: absolute;
    top: 0;
    overflow: hidden;
    .formContent {
      width: 500px;
      border-radius: 5px;
      background: var(--primary-white);
      .completeProfileCrossIcon {
        display: flex;
        justify-content: end;
        margin-top: 10px;
        margin-right: 8px;
        button {
          background: transparent;
          border: none;
        }
      }
      .completeProfileTitle {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .inputContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        .field {
          margin-bottom: 15px;
        }
      }
      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--off-white);
        background: var(--primary-white);
        padding: 16px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:focus {
          outline: 0;
        }
        &::placeholder {
          color: var(--light-grey);
        }
        &:disabled {
          background-color: var(--very-light-grey);
          cursor: no-drop;
          color: var(--disable-grey-shade);
        }
      }
      .createPassword {
        margin-top: 20px;
        label {
          margin-bottom: 8px;
          font-weight: 600;
          color: #8e8e8e;
        }
        .errorMsgs {
          color: var(--red);
          font-size: 12px;
          font-weight: 500;
          margin-top: 5px;
        }
      }
    }
    .submitBtn {
      margin-top: 20px;
      color: var(--primary-white);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 4px;
      background: var(--primary-green);
      width: 100%;
      padding: 15px;
      border: 0;
    }
    .changeProfileBtn {
      background: var(--off-white);
      color: var(--primary-black);
    }
  }
}
.userSec {
  display: none;
}
