.commonBtn {
  border: 2px solid var(--primary-green);
  background: transparent;
  height: 40px;
  border-radius: 50px;
  padding: 0px 10px;
  .text {
    font-weight: 600;
  }
}
