.main-content {
  margin-top: 60px !important;
  transition: margin-top 0.5s ease;
  /* Optional for smooth transition */
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  margin-top: 10px;
  margin-bottom: 40px;
  .logo-link {
    display: flex;
    align-items: center;
    .logo {
      position: relative;
      display: flex;
      width: 190px;
      .evluateName {
        color: var(--primary-black);
        font-weight: 500;
        margin-left: 2px;
        font-size: 25px;
      }
      .tagline {
        color: var(--primary-black);
        font-weight: 400;
        font-size: 12px;
        margin-top: -5px;
        margin-left: 2px;
        position: absolute;
      }
    }
  }
  .navbar-nav {
    margin-left: 40px;
  }
  .nav-link {
    color: var(--darker-grey);
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
  }
}
.nav-item {
  .active {
    .activeCreateBtn {
      background-color: var(--light-green) !important;
    }
  }
  .lightBtn {
    &:hover {
      background-color: var(--light-green) !important;
    }
  }
  .addBottomBorder {
    padding-bottom: 4px;
    padding-top: 4px;
    border-bottom: 1px solid var(--primary-green);
  }
}
.borderBottomLine {
  border-bottom: 1px solid var(--moderate-green);
}
.navbar {
  .navbar-toggler {
    border: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .assessmentCommonBtn .logoutBtn {
    border-radius: 100px !important;
    border-width: 2px !important;
    margin-left: 20px;
    font-weight: 600 !important;
  }
  .exitFullScreen {
    cursor: pointer;
    font-weight: 600;
    color: var(--light-black);
    font-size: 14px;
  }
}
.navbar-collapse {
  background-color: #fff;
}
.collapse.navbar-collapse {
  transition: height 0.3s ease-in-out;
  /* Smooth transition when collapsing */
  .navbar-nav {
    align-items: baseline;
    gap: 20px;
  }
}
.Btn {
  margin-left: auto;
  /* Stays on the right in larger screens */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  html[dir="rtl"] & {
    gap: 15px;
  }
  /* When the navbar is collapsed, ensure the button is visible */
  @media (max-width: 992px) {
    margin-left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }
  .profile-settings {
    position: relative;
    display: inline-block;
    align-self: end;
    .logo-place {
      cursor: pointer;
      align-items: center;
      background-color: var(--primary-green);
      border-radius: 50% !important;
      color: var(--primary-white);
      display: flex;
      height: 40px !important;
      justify-content: center;
      width: 40px !important;
      font-family: Montserrat;
      font-size: 25px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .popover {
    position: absolute;
    top: 50px;
    right: 0;
    width: 300px;
    background-color: var(--primary-white);
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 0;
    html[dir="rtl"] & {
      right: -245px;
    }
    hr {
      color: #b8b5b5;
      width: 100%;
      margin: 5px;
    }
    .popover-header {
      background-color: var(--very-light-green) !important;
      border-bottom: 1px solid var(--very-light-grey) !important;
      display: flex;
      flex-direction: column;
      padding: 16px !important;
      .logo-name-sec {
        display: flex;
        gap: 10px;
      }
      .email-date-section {
        display: flex;
        gap: 6px;
        flex-direction: column;
        align-items: baseline;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        word-break: break-all;
        color: var(--darker-grey);
        .date-section {
          font-size: 10px;
        }
      }
    }
    .company-logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #00cccc;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0; // Prevents the circle from shrinking due to flex
      span {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        color: var(--light-black);
      }
    }
    .company-name {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      color: var(--light-black);
    }
    .popover-body,
    .popover-footer {
      padding: 10px 16px;
    }
    .popover-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .popover-item {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      color: var(--darker-grey);
    }
    .popover-item:hover {
      background-color: var(--dark-white);
    }
  }
  .assessmentCommonBtn .logoutBtn {
    border-radius: 100px !important;
    border-width: 2px !important;
    font-weight: 600 !important;
    height: 40px;
  }
}
.navToggle {
  margin-bottom: 150px;
  transition: 0.5s ease-in-out;
}
